var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"obody"},[_c('HomePageTop'),_c('UserAgreement',{ref:"UserAgreement"}),_c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.loading),expression:"loading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"registerMainBox"},[_c('div',{staticClass:"logo-box main"},[_c('h2',{staticClass:"fl"},[_vm._v(_vm._s(_vm.$fanyi('注册信息')))]),_c('div',{staticClass:"fl fenge"})]),(_vm.step == 1)?_c('div',{staticClass:"register"},[_c('div',[_c('div',[_c('h3',{staticClass:"firstStepTitle"},[_c('p',{staticClass:"xuYaoXinXi"},[_vm._v(_vm._s(_vm.$fanyi('填写信息')))]),_c('p',{staticClass:"Toast"},[_vm._v(" "+_vm._s(_vm.$fanyi('请将您的个人信息填入下方'))+" ")])])]),_c('el-form',{ref:"formRef",staticClass:"firStepForm",attrs:{"model":_vm.formregister,"rules":_vm.formRules}},[_c('ul',[_c('li',[_c('el-form-item',{attrs:{"prop":"user_last_name","label":_vm.$fanyi('姓氏')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formregister.user_last_name),expression:"formregister.user_last_name"}],staticClass:"ipt",class:{
                  istrueInput: _vm.formInputTrue.user_last_name,
                },attrs:{"autocomplete":"new-password","placeholder":_vm.$fanyi('请输入姓氏')},domProps:{"value":(_vm.formregister.user_last_name)},on:{"blur":function($event){return _vm.validateField('user_last_name')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formregister, "user_last_name", $event.target.value)}}}),(_vm.formInputTrue.user_last_name)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1),_c('li',[_c('el-form-item',{attrs:{"prop":"user_name","label":_vm.$fanyi('名字')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formregister.user_name),expression:"formregister.user_name"}],staticClass:"ipt",class:{
                  istrueInput: _vm.formInputTrue.user_name,
                },attrs:{"placeholder":_vm.$fanyi('请输入名字')},domProps:{"value":(_vm.formregister.user_name)},on:{"blur":function($event){return _vm.validateField('user_name')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formregister, "user_name", $event.target.value)}}}),(_vm.formInputTrue.user_name)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1),_c('li',[_c('el-form-item',{attrs:{"label":_vm.$fanyi('州'),"prop":"state"}},[_c('el-select',{staticClass:"xuanZeGuoJia",class:{
                  istrueInput: _vm.formInputTrue.state,
                },attrs:{"name":"","filterable":"","placeholder":_vm.$fanyi('请选择地区')},on:{"change":function($event){return _vm.validateField('state')},"blur":function($event){return _vm.validateField('state')}},model:{value:(_vm.formregister.state),callback:function ($$v) {_vm.$set(_vm.formregister, "state", $$v)},expression:"formregister.state"}},_vm._l((_vm.$imdata.provincesList),function(item,index){return _c('el-option',{key:item + index,attrs:{"label":item,"value":item}})}),1),(_vm.formInputTrue.state)?_c('div',{staticClass:"gou",staticStyle:{"margin-right":"20px"}},[_vm._v(" ✔ ")]):_vm._e()],1)],1),_c('li',[_c('el-form-item',{attrs:{"prop":"email","label":_vm.$fanyi('邮箱')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formregister.email),expression:"formregister.email"}],staticClass:"ipt",class:{
                  istrueInput: _vm.formInputTrue.email,
                },attrs:{"placeholder":_vm.$fanyi('请输入邮箱')},domProps:{"value":(_vm.formregister.email)},on:{"blur":function($event){return _vm.validateField('email')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formregister, "email", $event.target.value)}}}),(_vm.formInputTrue.email)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1),_c('li',{staticClass:"guoJiaQuHao"},[_c('el-form-item',{attrs:{"label":_vm.$fanyi('手机号'),"prop":"mobile"}},[_c('div',{staticClass:"shouJIHaoCon"},[_c('div',{staticClass:"quHao",class:{
                    istrueInput: _vm.formInputTrue.mobile,
                  }},[_vm._v(" "+_vm._s(_vm.formregister.countryPhone)+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formregister.mobile),expression:"formregister.mobile"}],staticClass:"iptSmall",class:{
                    istrueInput: _vm.formInputTrue.mobile,
                  },attrs:{"oninput":"this.value=this.value.replace(/[^\\d]/g,'')","placeholder":this.$fanyi('请输入您的手机号')},domProps:{"value":(_vm.formregister.mobile)},on:{"blur":function($event){return _vm.validateField('mobile')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formregister, "mobile", $event.target.value)}}}),(_vm.formInputTrue.mobile)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])])],1),_c('li',{staticClass:"yanzhengma"},[_c('el-form-item',{attrs:{"label":_vm.$fanyi('验证码'),"prop":"num"}},[_c('div',{staticClass:"yanzhengmaBox"},[_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formregister.num),expression:"formregister.num"}],staticClass:"iptSmall",class:{
                      istrueInput: !!_vm.formInputTrue.num,
                    },attrs:{"placeholder":this.$fanyi('请输入验证码')},domProps:{"value":(_vm.formregister.num)},on:{"blur":function($event){return _vm.validateField('num')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formregister, "num", $event.target.value)}}}),(_vm.formInputTrue.num && _vm.show === true)?_c('div',{staticClass:"gou regCodegou"},[_vm._v(" ✔ ")]):_vm._e(),(_vm.formInputTrue.num && _vm.show === false)?_c('div',{staticClass:"gou newRegCodegou"},[_vm._v(" ✔ ")]):_vm._e()]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],on:{"click":function($event){$event.preventDefault();return _vm.sendSMS.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$fanyi('发送验证码'))+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.show),expression:"!show"}],staticStyle:{"opacity":"0.6"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.Countdown)+" S ")])])])],1),_c('li',[_c('el-form-item',{staticClass:"regerror",attrs:{"prop":"password","label":_vm.$fanyi('密码')}},[_c('el-input',{staticClass:"ipt passWordInputBox",class:{
                  istrueInput: _vm.formInputTrue.password,
                },attrs:{"type":"password","autocomplete":"new-password","show-password":"","placeholder":_vm.$fanyi('请输入密码')},on:{"blur":function($event){return _vm.validateField('password')},"input":_vm.passwordChannelInputLimit},model:{value:(_vm.formregister.password),callback:function ($$v) {_vm.$set(_vm.formregister, "password", $$v)},expression:"formregister.password"}})],1)],1),_c('li',[_c('el-form-item',{attrs:{"prop":"repassword","label":_vm.$fanyi('密码确认')}},[_c('el-input',{staticClass:"ipt passWordInputBox",class:{
                  istrueInput: _vm.formInputTrue.repassword,
                },attrs:{"type":"password","autocomplete":"new-password","show-password":"","placeholder":_vm.$fanyi('请再次输入密码')},on:{"blur":function($event){return _vm.validateField('repassword')},"input":_vm.repasswordChannelInputLimit},model:{value:(_vm.formregister.repassword),callback:function ($$v) {_vm.$set(_vm.formregister, "repassword", $$v)},expression:"formregister.repassword"}})],1)],1),_c('li',[_c('el-form-item',{attrs:{"label":_vm.$fanyi('SSN（选填）'),"prop":"user_id_card"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formregister.user_id_card),expression:"formregister.user_id_card"}],staticClass:"ipt",class:{
                  istrueInput:
                    _vm.formInputTrue.user_id_card &&
                    _vm.ssnStatus === true &&
                    _vm.formregister.user_id_card != undefined,
                },attrs:{"placeholder":_vm.$fanyi('请输入SSN')},domProps:{"value":(_vm.formregister.user_id_card)},on:{"blur":function($event){return _vm.validateField('user_id_card')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formregister, "user_id_card", $event.target.value)}}}),(_vm.formInputTrue.user_id_card &&
                  _vm.ssnStatus === true &&
                  _vm.formregister.user_id_card != undefined
                )?_c('div',{staticClass:"gou"},[_vm._v(" ✔ ")]):_vm._e()])],1),_c('li',[_c('el-form-item',{staticClass:"xieYi",attrs:{"label":""}},[_c('button',{staticClass:"nextBtn",on:{"click":function($event){$event.preventDefault();return _vm.getNext.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$fanyi('同意以上协议，进入下一步'))+" ")]),_c('div',{staticClass:"fuWuXieYi"},[_c('el-checkbox',{model:{value:(_vm.agreement),callback:function ($$v) {_vm.agreement=$$v},expression:"agreement"}}),_c('a',{attrs:{"href":"javascript:;"}},[_c('span',{staticStyle:{"border-bottom":"1px  solid transparent","margin":"0 5px"}},[_vm._v(_vm._s(_vm.$fanyi('RAKUMART')))]),_c('span',{staticClass:"protocol",on:{"click":_vm.protocol}},[_vm._v(_vm._s(_vm.$fanyi('的用户协议和隐私政策')))])])],1)])],1),_c('li')])])],1)]):_vm._e(),(_vm.step == 2)?_c('div',{staticClass:"registration_complete"},[_c('div',{staticClass:"registration_completeCon"},[_vm._m(0),_c('div',{staticClass:" showSpan"},[_c('p',{staticStyle:{"font-size":"24px","font-family":"Roboto","font-weight":"bold","color":"#222222","margin-bottom":"5px"}},[_vm._v(" "+_vm._s(_vm.$fanyi('帐号注册完成'))+" ")]),_c('p',{staticStyle:{"font-size":"18px","font-family":"Roboto","font-weight":"400","color":"#999999","margin-bottom":"60px"}},[_vm._v(" "+_vm._s(_vm.$fanyi('非常感谢您的注册'))+" ")])]),_c('div',{staticClass:"registration_completeConOpt"},[_c('button',{staticClass:"log",on:{"click":function($event){return _vm.$fun.toPage('/login')}}},[_vm._v(" "+_vm._s(_vm.$fanyi('去登录'))+" ")])])])]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"registration_completeConOpt"},[_c('img',{staticClass:"successImg",attrs:{"src":require("../../assets/login_success.png"),"alt":""}})])}]

export { render, staticRenderFns }